import React, { useState, useEffect } from 'react';
import { Form, Container, Button, Card, Spinner, Modal } from 'react-bootstrap';
import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc, updateDoc, increment } from "firebase/firestore";
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './/../Global/shared-css.css';
import Err404 from './404';
import LoadingSceen from './Loading/Loading';
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
    apiKey: "AIzaSyA2TJgXU866pvLJlBsAazRAPUnzceXeN7A",
    authDomain: "edu-nug-myanmar.firebaseapp.com",
    projectId: "edu-nug-myanmar",
    storageBucket: "edu-nug-myanmar.appspot.com",
    messagingSenderId: "973737820638",
    appId: "1:973737820638:web:a4340ccf6886bf97a75d4f",
    measurementId: "G-EH685D8KXK"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function ProtectedPage() {
    const [userInputedPassword, setUserInputedPassword] = useState(''); // [state, setState
    const [linkPassword, setLinkPassword] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showIncorrectModal, setShowIncorrectModal] = useState(false);

    // const navigate = useNavigate();

    // Fort Knox Passwords - Secure enough for almost anything, like root or administrator passwords. At least 16 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character, and no dictionary words.
    const EncryptedValue = 'ra(APy2`}[RlRw0Ga?dh9gity+TR[O';

    function decryptPassword(password, EncryptedKey) {
        try {
            const bytes = CryptoJS.AES.decrypt(password, EncryptedKey);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            return originalText;
        } catch (error) {
            // setShowIncorrectModal(true);
            toast.error('Failed to decrypt or parse the password:', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error('Failed to decrypt or parse the password:', error);
            return null;
        }
    }

    let params = new URLSearchParams(window.location.search);
    let cpass = params.get('cpass');

    useEffect(() => {
        // Fetch user document with specific email from Firestore
        const fetchData = async () => {
            setLoading(true);
            const docRef = doc(db, 'goto', cpass.substring(1));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const linkpassword = docSnap.data().password;
                const redirectUrl = docSnap.data().url;
                setRedirectUrl(redirectUrl);
                setLinkPassword(linkpassword);
                await updateDoc(docRef, {
                    clicks: increment(1)
                });
            } else {
                <Err404 />
            }
            setLoading(false);
        };

        fetchData();
    }, [cpass.substring(1)]); // Dependency array

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (userInputedPassword === '') {
            // setShowModal(true);
            toast.error('Please enter the password.', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            if (decryptPassword(linkPassword, EncryptedValue) == userInputedPassword) {
                window.location.replace(redirectUrl)
                setIsVerified(true);
            } else if (decryptPassword(linkPassword, EncryptedValue) !== userInputedPassword) {
                // setShowIncorrectModal(true);
                toast.error('Incorrect password! or Something went wrong. Please try again.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                // setShowIncorrectModal(true);
                toast.error('Incorrect password! or Something went wrong. Please try again.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

    };

    // const bytes = CryptoJS.AES.decrypt(dbPassword, 'XX2sJnT9(Q)<Z!TP19lP4Dc4@*>>QZ');
    // const decryptedPass = bytes.toString(CryptoJS.enc.Utf8);

    if (!isVerified) {
        return (
            loading ? <LoadingSceen /> :
                <>

                    < Container fluid className='passcontainer bg-light align-items-center d-flex navless-container' >
                        <Card className='m-auto formwidth passCard'>
                            <div className="d-flex">
                                <img src="https://cdn.egov.nugmyanmar.org/assets/edunug/logo/edu.NUG.png" width={80} className="godotnuglogo align-items-center" />
                                <div className="passtitle">
                                    <p className="text-left h4 protectTitle">Password Protected</p>
                                    <span className="text-left text-mute redirectLink">edu.nugmyanmar.org/{cpass.substring(1)}</span>
                                </div>
                            </div>
                            <Form onSubmit={handleFormSubmit} >
                                <Form.Group className="my-3" controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="Enter the password.."
                                        onChange={e => setUserInputedPassword(e.target.value)} />
                                    <Form.Text className="text-muted smallinfo">If you do not have the password, please contact the agency responsible for providing you with the short link.</Form.Text>
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className='loginBtn'
                                        onClick={(event) => handleFormSubmit(event)}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Card>

                    </Container >
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please enter the password.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showIncorrectModal} onHide={() => setShowIncorrectModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Incorrect password! or Something went wrong. Please try again.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowIncorrectModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal> */}
                </>
        );
    } else if (isVerified) {

        return (
            < Container fluid className='passcontainer bg-light align-items-center d-flex navless-container' >
                <Card className='m-auto formwidth passCard'>
                    <div className="d-flex">
                        <Spinner animation="grow" style={{ color: '#2F3C87' }} />
                        <div className="passtitle">
                            <p className="text-left h4 protectTitle">Redirecting...</p>
                            <spam className="text-left text-mute redirectLink">edu.nugmyanmar.org/{cpass.substring(1)}</spam>
                        </div>
                    </div>
                </Card>

            </Container >
        );
    }
}

ProtectedPage.propTypes = {
    isProtected: PropTypes.bool,
    correctPassword: PropTypes.string,
    fsRedirectUrl: PropTypes.string,
    isActive: PropTypes.bool,
    loading: PropTypes.bool,
};

export default ProtectedPage;